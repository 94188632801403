export default {
  'Accepted file types: .jpg, .jpeg, .png': {
    en: 'Accepted file types: .jpg, .jpeg, .png',
    ja: 'Accepted file types: .jpg, .jpeg, .png',
    et: 'Accepted file types: .jpg, .jpeg, .png',
    lt: 'Accepted file types: .jpg, .jpeg, .png',
    ch: 'Accepted file types: .jpg, .jpeg, .png',
    ph: 'Accepted file types: .jpg, .jpeg, .png',
  },
  addBadge: {
    en: 'Add badges for this challenge',
    ja: 'Add badges for this challenge',
    et: 'Add badges for this challenge',
    lt: 'Add badges for this challenge',
    ch: 'Add badges for this challenge',
    ph: 'Add badges for this challenge',
  },
  addChallenge: {
    en: 'Add challenges for this badge',
    ja: 'Add challenges for this badge',
    et: 'Add challenges for this badge',
    lt: 'Add challenges for this badge',
    ch: 'Add challenges for this badge',
    ph: 'Add challenges for this badge',
  },
  'A name is required for badge category': {
    en: 'A name is required for badge category',
    ja: 'A name is required for badge category',
    et: 'A name is required for badge category',
    lt: 'A name is required for badge category',
    ch: 'A name is required for badge category',
    ph: 'A name is required for badge category',
  },
  'Add Badge': {
    en: 'Add Badge',
    ja: 'Add Badge',
    et: 'Add Badge',
    lt: 'Add Badge',
    ch: 'Add Badge',
    ph: 'Add Badge',
  },
  'Add Challenge': {
    en: 'Add Challenge',
    ja: 'Add Challenge',
    et: 'Add Challenge',
    lt: 'Add Challenge',
    ch: 'Add Challenge',
    ph: 'Add Challenge',
  },
  'Added Challenges': {
    en: 'Added Challenges',
    ja: 'Added Challenges',
    et: 'Added Challenges',
    lt: 'Added Challenges',
    ch: 'Added Challenges',
    ph: 'Added Challenges',
  },
  'Added Badges': {
    en: 'Added Badges',
    ja: 'Added Badges',
    et: 'Added Badges',
    lt: 'Added Badges',
    ch: 'Added Badges',
    ph: 'Added Badges',
  },
  'Add/Modify Badge Category': {
    en: 'Add/Modify Badge Category',
    ja: 'バッジカテゴリの追加/変更',
    et: 'Lisa/muuda märgi kategooriat',
    lt: 'Add/Modify Badge Category',
    ch: 'Add/Modify Badge Category',
    ph: 'Add/Modify Badge Category',
  },
  'Add/Modify Badge': {
    en: 'Add/Modify Badge',
    ja: 'Add/Modify Badge',
    et: 'Add/Modify Badge',
    lt: 'Add/Modify Badge',
    ch: 'Add/Modify Badge',
    ph: 'Add/Modify Badge',
  },
  'Add/Modify Challenge': {
    en: 'Add/Modify Challenge',
    ja: 'Add/Modify Challenge',
    et: 'Add/Modify Challenge',
    lt: 'Add/Modify Challenge',
    ch: 'Add/Modify Challenge',
    ph: 'Add/Modify Challenge',
  },
  'Add a New Badge': {
    en: 'Add a New Badge',
    ja: 'Add a New Badge',
    et: 'Add a New Badge',
    lt: 'Add a New Badge',
    ch: 'Add a New Badge',
    ph: 'Add a New Badge',
  },
  'Add a New Challenge': {
    en: 'Add a New Challenge',
    ja: 'Add a New Challenge',
    et: 'Add a New Challenge',
    lt: 'Add a New Challenge',
    ch: 'Add a New Challenge',
    ph: 'Add a New Challenge',
  },
  'An image is required for this badge': {
    en: 'An image is required for this badge',
    ja: 'An image is required for this badge',
    et: 'An image is required for this badge',
    lt: 'An image is required for this badge',
    ch: 'An image is required for this badge',
    ph: 'An image is required for this badge',
  },
  'An image is required for this challenge': {
    en: 'An image is required for this challenge',
    ja: 'An image is required for this challenge',
    et: 'An image is required for this challenge',
    lt: 'An image is required for this challenge',
    ch: 'An image is required for this challenge',
    ph: 'An image is required for this challenge',
  },
  Badges: {
    en: 'Badges',
    et: 'Märgid',
    ja: 'バッジ',
    lt: 'Badges',
    ch: 'Badges',
    ph: 'Badges',
  },
  Challenges: {
    en: 'Challenges',
    et: 'Väljakutsed',
    ja: '挑戦',
    lt: 'Challenges',
    ch: 'Challenges',
    ph: 'Challenges',
  },
  'Create badge before adding challenges': {
    en: 'Create badge before adding challenges',
    et: 'Create badge before adding challenges',
    ja: 'Create badge before adding challenges',
    lt: 'Create badge before adding challenges',
    ch: 'Create badge before adding challenges',
    ph: 'Create badge before adding challenges',
  },
  'Create challenge before adding badges': {
    en: 'Create challenge before adding badges',
    et: 'Create challenge before adding badges',
    ja: 'Create challenge before adding badges',
    lt: 'Create challenge before adding badges',
    ch: 'Create challenge before adding badges',
    ph: 'Create challenge before adding badges',
  },
  'Badge Categories': {
    en: 'Badge Categories',
    ja: 'Badge Categories',
    et: 'Badge Categories',
    lt: 'Badge Categories',
    ch: 'Badge Categories',
    ph: 'Badge Categories',
  },
  'Challenge Categories': {
    en: 'Challenge Categories',
    ja: 'Challenge Categories',
    et: 'Challenge Categories',
    lt: 'Challenge Categories',
    ch: 'Challenge Categories',
    ph: 'Challenge Categories',
  },
  'Badge Creator': {
    en: 'Badge Creator',
    ja: 'Badge Creator',
    et: 'Badge Creator',
    lt: 'Badge Creator',
    ch: 'Badge Creator',
    ph: 'Badge Creator',
  },
  'Challenge Creator': {
    en: 'Challenge Creator',
    ja: 'Challenge Creator',
    et: 'BadChallengege Creator',
    lt: 'Challenge Creator',
    ch: 'Challenge Creator',
    ph: 'Challenge Creator',
  },
  'Badge category added': {
    en: 'Badge category added',
    ja: 'Badge category added',
    et: 'Badge category added',
    lt: 'Badge category added',
    ch: 'Badge category added',
    ph: 'Badge category added',
  },
  'Badge category edited': {
    en: 'Badge category edited',
    ja: 'Badge category edited',
    et: 'Badge category edited',
    lt: 'Badge category edited',
    ch: 'Badge category edited',
    ph: 'Badge category edited',
  },
  'Badge category deleted': {
    en: 'Badge category deleted',
    ja: 'Badge category deleted',
    et: 'Badge category deleted',
    lt: 'Badge category deleted',
    ch: 'Badge category deleted',
    ph: 'Badge category deleted',
  },
  'Badge created': {
    en: 'Badge created',
    ja: 'Badge created',
    et: 'Badge created',
    lt: 'Badge created',
    ch: 'Badge created',
    ph: 'Badge created',
  },
  'Challenge created': {
    en: 'Challenge created',
    ja: 'Challenge created',
    et: 'Challenge created',
    lt: 'Challenge created',
    ch: 'Challenge created',
    ph: 'Challenge created',
  },
  'Badge Description': {
    en: 'Badge Description',
    ja: 'Badge Description',
    et: 'Badge Description',
    lt: 'Badge Description',
    ch: 'Badge Description',
    ph: 'Badge Description',
  },
  'Challenge Description': {
    en: 'Challenge Description',
    ja: 'Challenge Description',
    et: 'Challenge Description',
    lt: 'Challenge Description',
    ch: 'Challenge Description',
    ph: 'Challenge Description',
  },
  'Badge deleted': {
    en: 'Badge deleted',
    ja: 'Badge deleted',
    et: 'Badge deleted',
    lt: 'Badge deleted',
    ch: 'Badge deleted',
    ph: 'Badge deleted',
  },
  'Challenge deleted': {
    en: 'Challenge deleted',
    ja: 'Challenge deleted',
    et: 'Challenge deleted',
    lt: 'Challenge deleted',
    ch: 'Challenge deleted',
    ph: 'Challenge deleted',
  },
  'Badge Difficulty': {
    en: 'Badge Difficulty',
    ja: 'Badge Difficulty',
    et: 'Badge Difficulty',
    lt: 'Badge Difficulty',
    ch: 'Badge Difficulty',
    ph: 'Badge Difficulty',
  },
  'Challenge Difficulty': {
    en: 'Challenge Difficulty',
    ja: 'Challenge Difficulty',
    et: 'BadChallengege Difficulty',
    lt: 'Challenge Difficulty',
    ch: 'Challenge Difficulty',
    ph: 'Challenge Difficulty',
  },
  'Badge edited': {
    en: 'Badge edited',
    ja: 'Badge edited',
    et: 'Badge edited',
    lt: 'Badge edited',
    ch: 'Badge edited',
    ph: 'Badge edited',
  },
  'Challenge edited': {
    en: 'Challenge edited',
    ja: 'Challenge edited',
    et: 'Challenge edited',
    lt: 'Challenge edited',
    ch: 'Challenge edited',
    ph: 'Challenge edited',
  },
  'Badge Image': {
    en: 'Badge Image',
    ja: 'Badge Image',
    et: 'Badge Image',
    lt: 'Badge Image',
    ch: 'Badge Image',
    ph: 'Badge Image',
  },
  'Badge Images': {
    en: 'Badge Images',
    ja: 'Badge Images',
    et: 'Badge Images',
    lt: 'Badge Images',
    ch: 'Badge Images',
    ph: 'Badge Images',
  },
  'Challenge Image': {
    en: 'Challenge Image',
    ja: 'Challenge Image',
    et: 'Challenge Image',
    lt: 'Challenge Image',
    ch: 'Challenge Image',
    ph: 'Challenge Image',
  },
  'Challenge Images': {
    en: 'Challenge Images',
    ja: 'Challenge Images',
    et: 'Challenge Images',
    lt: 'Challenge Images',
    ch: 'Challenge Images',
    ph: 'Challenge Images',
  },
  'Save custom badge order': {
    en: 'Save custom badge order',
    ja: 'Save custom badge order',
    et: 'Save custom badge order',
    lt: 'Save custom badge order',
    ch: 'Save custom badge order',
    ph: 'Save custom badge order',
  },
  'Save custom challenge order': {
    en: 'Save custom challenge order',
    ja: 'Save custom challenge order',
    et: 'Save custom challenge order',
    lt: 'Save custom challenge order',
    ch: 'Save custom challenge order',
    ph: 'Save custom challenge order',
  },
  'How to earn this badge?': {
    en: 'How to earn this badge?',
    ja: 'How to earn this badge?',
    et: 'How to earn this badge?',
    lt: 'How to earn this badge?',
    ch: 'How to earn this badge?',
    ph: 'How to earn this badge?',
  },
  'Badge Info': {
    en: 'Badge Info',
    ja: 'Badge Info',
    et: 'Badge Info',
    lt: 'Badge Info',
    ch: 'Badge Info',
    ph: 'Badge Info',
  },
  'Challenge Info': {
    en: 'Challenge Info',
    ja: 'Challenge Info',
    et: 'Challenge Info',
    lt: 'Challenge Info',
    ch: 'Challenge Info',
    ph: 'Challenge Info',
  },
  'Badge Name': {
    en: 'Badge Name',
    ja: 'Badge Name',
    et: 'Badge Name',
    lt: 'Badge Name',
    ch: 'Badge Name',
    ph: 'Badge Name',
  },
  'Challenge Name': {
    en: 'Challenge Name',
    ja: 'Challenge Name',
    et: 'Challenge Name',
    lt: 'Challenge Name',
    ch: 'Challenge Name',
    ph: 'Challenge Name',
  },
  Cancel: {
    en: 'Cancel',
    ja: 'キャンセル',
    et: 'Tühista',
    lt: 'Cancel',
    ch: 'Cancel',
    ph: 'Cancel',
  },
  'Badge added': {
    en: 'Badge added',
    ja: 'Badge added',
    et: 'Badge added',
    lt: 'Badge added',
    ch: 'Badge added',
    ph: 'Badge added',
  },
  'Challenge added': {
    en: 'Challenge added',
    ja: 'Challenge added',
    et: 'Challenge added',
    lt: 'Challenge added',
    ch: 'Challenge added',
    ph: 'Challenge added',
  },
  'Badge removed': {
    en: 'Badge removed',
    ja: 'Badge removed',
    et: 'Badge removed',
    lt: 'Badge removed',
    ch: 'Badge removed',
    ph: 'Badge removed',
  },
  'Challenge removed': {
    en: 'Challenge removed',
    ja: 'Challenge removed',
    et: 'Challenge removed',
    lt: 'Challenge removed',
    ch: 'Challenge removed',
    ph: 'Challenge removed',
  },
  'Choose a category (optional)': {
    en: 'Choose a category (optional)',
    et: 'Choose a category (optional)',
    ja: 'Choose a category (optional)',
    lt: 'Choose a category (optional)',
    ch: 'Choose a category (optional)',
    ph: 'Choose a category (optional)',
  },
  'Choose a badge': {
    en: 'Choose a badge',
    et: 'Choose a badge',
    ja: 'Choose a badge',
    lt: 'Choose a badge',
    ch: 'Choose a badge',
    ph: 'Choose a badge',
  },
  'Choose a challenge': {
    en: 'Choose a challenge',
    et: 'Choose a challenge',
    ja: 'Choose a challenge',
    lt: 'Choose a challenge',
    ch: 'Choose a challenge',
    ph: 'Choose a challenge',
  },
  'Come up with a compelling title for your badge...': {
    en: 'Come up with a compelling title for your badge...',
    ja: 'Come up with a compelling title for your badge...',
    et: 'Come up with a compelling title for your badge...',
    lt: 'Come up with a compelling title for your badge...',
    ch: 'Come up with a compelling title for your badge...',
    ph: 'Come up with a compelling title for your badge...',
  },
  'Come up with a compelling title for your challenge...': {
    en: 'Come up with a compelling title for your challenge...',
    ja: 'Come up with a compelling title for your challenge...',
    et: 'Come up with a compelling title for your challenge...',
    lt: 'Come up with a compelling title for your challenge...',
    ch: 'Come up with a compelling title for your challenge...',
    ph: 'Come up with a compelling title for your challenge...',
  },
  'Commonly Asked Questions': {
    en: 'Commonly Asked Questions',
    ja: 'Commonly Asked Questions',
    et: 'Commonly Asked Questions',
    lt: 'Commonly Asked Questions',
    ch: 'Commonly Asked Questions',
    ph: 'Commonly Asked Questions',
  },
  'Content is malformed. Please tell a crew member about this': {
    en: 'Content is malformed. Please tell a crew member about this',
    ja: 'Content is malformed. Please tell a crew member about this',
    et: 'Content is malformed. Please tell a crew member about this',
    lt: 'Content is malformed. Please tell a crew member about this',
    ch: 'Content is malformed. Please tell a crew member about this',
    ph: 'Content is malformed. Please tell a crew member about this',
  },
  'Cover Image': {
    en: 'Cover Image',
    ja: 'Cover Image',
    et: 'Cover Image',
    lt: 'Cover Image',
    ch: 'Cover Image',
    ph: 'Cover Image',
  },
  'Created Date': {
    en: 'Created Date',
    ja: '作成日',
    et: 'Loomise kuupäev',
    lt: 'Created Date',
    ch: 'Created Date',
    ph: 'Created Date',
  },
  days: {
    en: 'days',
    ja: 'days',
    et: 'days',
    lt: 'days',
    ch: 'days',
    ph: 'days',
  },
  'Danger Zone': {
    en: 'Danger Zone',
    ja: 'Danger Zone',
    et: 'Danger Zone',
    lt: 'Danger Zone',
    ch: 'Danger Zone',
    ph: 'Danger Zone',
  },
  Description: {
    en: 'Description',
    ja: '説明',
    et: 'Kirjeldus',
    lt: 'Description',
    ch: 'Description',
    ph: 'Description',
  }, // description for badge or badge category
  'Delete Badge': {
    en: 'Delete Badge',
    ja: 'Delete Badge',
    et: 'Delete Badge',
    lt: 'Delete Badge',
    ch: 'Delete Badge',
    ph: 'Delete Badge',
  },
  'Delete Challenge': {
    en: 'Delete Challenge',
    ja: 'Delete Challenge',
    et: 'Delete Challenge',
    lt: 'Delete Challenge',
    ch: 'Delete Challenge',
    ph: 'Delete Challenge',
  },
  'Delete Category': {
    en: 'Delete Category',
    ja: 'カテゴリを削除',
    et: 'Kustuta kategooria',
    lt: 'Delete Category',
    ch: 'Delete Category',
    ph: 'Delete Category',
  },
  'Enabling this allows the badge to be awarded to VIVINAUTS without the need for staff approval': {
    en: 'Enabling this allows the badge to be awarded to VIVINAUTS without the need for staff approval',
    ja: 'Enabling this allows the badge to be awarded to VIVINAUTS without the need for staff approval',
    et: 'Enabling this allows the badge to be awarded to VIVINAUTS without the need for staff approval',
    lt: 'Enabling this allows the badge to be awarded to VIVINAUTS without the need for staff approval',
    ch: 'Enabling this allows the badge to be awarded to VIVINAUTS without the need for staff approval',
    ph: 'Enabling this allows the badge to be awarded to VIVINAUTS without the need for staff approval',
  },
  'Enabling this allows the challenge to be awarded to VIVINAUTS without the need for staff approval': {
    en: 'Enabling this allows the challenge to be awarded to VIVINAUTS without the need for staff approval',
    ja: 'Enabling this allows the challenge to be awarded to VIVINAUTS without the need for staff approval',
    et: 'Enabling this allows the challenge to be awarded to VIVINAUTS without the need for staff approval',
    lt: 'Enabling this allows the challenge to be awarded to VIVINAUTS without the need for staff approval',
    ch: 'Enabling this allows the challenge to be awarded to VIVINAUTS without the need for staff approval',
    ph: 'Enabling this allows the challenge to be awarded to VIVINAUTS without the need for staff approval',
  },
  'Estimated Time to Complete': {
    en: 'Estimated Time to Complete',
    ja: 'Estimated Time to Complete',
    et: 'Estimated Time to Complete',
    lt: 'Estimated Time to Complete',
    ch: 'Estimated Time to Complete',
    ph: 'Estimated Time to Complete',
  },
  'How difficult is it to complete this badge?': {
    en: 'How difficult is it to complete this badge?',
    ja: 'How difficult is it to complete this badge?',
    et: 'How difficult is it to complete this badge?',
    lt: 'How difficult is it to complete this badge?',
    ch: 'How difficult is it to complete this badge?',
    ph: 'How difficult is it to complete this badge?',
  },
  'How difficult is it to complete this challenge?': {
    en: 'How difficult is it to complete this challenge?',
    ja: 'How difficult is it to complete this challenge?',
    et: 'How difficult is it to complete this challenge?',
    lt: 'How difficult is it to complete this challenge?',
    ch: 'How difficult is it to complete this challenge?',
    ph: 'How difficult is it to complete this challenge?',
  },
  'How long will it take to complete this badge?': {
    en: 'How long will it take to complete this badge?',
    ja: 'How long will it take to complete this badge?',
    et: 'How long will it take to complete this badge?',
    lt: 'How long will it take to complete this badge?',
    ch: 'How long will it take to complete this badge?',
    ph: 'How long will it take to complete this badge?',
  },
  'How long will it take to complete this challenge?': {
    en: 'How long will it take to complete this challenge?',
    ja: 'How long will it take to complete this challenge?',
    et: 'How long will it take to complete this challenge?',
    lt: 'How long will it take to complete this challenge?',
    ch: 'How long will it take to complete this challenge?',
    ph: 'How long will it take to complete this challenge?',
  },
  'How To Earn': {
    en: 'How To Earn',
    ja: 'How To Earn',
    et: 'How To Earn',
    lt: 'How To Earn',
    ch: 'How To Earn',
    ph: 'How To Earn',
  },
  'How To Complete': {
    en: 'How To Complete',
    ja: 'How To Complete',
    et: 'How To Complete',
    lt: 'How To Complete',
    ch: 'How To Complete',
    ph: 'How To Complete',
  },
  'How to complete this challenge?': {
    en: 'How to complete this challenge?',
    ja: 'How to complete this challenge?',
    et: 'How to complete this challenge?',
    lt: 'How to complete this challenge?',
    ch: 'How to complete this challenge?',
    ph: 'How to complete this challenge?',
  },
  hours: {
    en: 'hours',
    ja: 'hours',
    et: 'hours',
    lt: 'hours',
    ch: 'hours',
    ph: 'hours',
  },
  'Make this badge self-earning': {
    en: 'Make this badge self-earning',
    ja: 'Make this badge self-earning',
    et: 'Make this badge self-earning',
    lt: 'Make this badge self-earning',
    ch: 'Make this badge self-earning',
    ph: 'Make this badge self-earning',
  },
  'Make this challenge self-earning': {
    en: 'Make this challenge self-earning',
    ja: 'Make this challenge self-earning',
    et: 'Make this challenge self-earning',
    lt: 'Make this challenge self-earning',
    ch: 'Make this challenge self-earning',
    ph: 'Make this challenge self-earning',
  },
  Name: {
    en: 'Name',
    ja: '名称',
    et: 'nimetus',
    lt: 'Name',
    ch: 'Name',
    ph: 'Name',
  }, // name or title for badge or badge category
  'New Badge': {
    en: 'New Badge',
    ja: 'New Badge',
    et: 'New Badge',
    lt: 'New Badge',
    ch: 'New Badge',
    ph: 'New Badge',
  },
  'New Challenge': {
    en: 'New Challenge',
    ja: 'New Challenge',
    et: 'New Challenge',
    lt: 'New Challenge',
    ch: 'New Challenge',
    ph: 'New Challenge',
  },
  'New Category': {
    en: 'New Category',
    ja: 'New Category',
    et: 'New Category',
    lt: 'New Category',
    ch: 'New Category',
    ph: 'New Category',
  },
  'No badge has been added to this challenge yet.': {
    en: 'No badge has been added to this challenge yet.',
    ja: 'No badge has been added to this challenge yet.',
    et: 'No badge has been added to this challenge yet.',
    lt: 'No badge has been added to this challenge yet.',
    ch: 'No badge has been added to this challenge yet.',
    ph: 'No badge has been added to this challenge yet.',
  },
  'No challenge has been added to this badge yet.': {
    en: 'No challenge has been added to this badge yet.',
    ja: 'No challenge has been added to this badge yet.',
    et: 'No challenge has been added to this badge yet.',
    lt: 'No challenge has been added to this badge yet.',
    ch: 'No challenge has been added to this badge yet.',
    ph: 'No challenge has been added to this badge yet.',
  },
  'Recommended image size: 100 x 100 pixels': {
    en: 'Recommended image size: 100 x 100 pixels',
    ja: 'Recommended image size: 100 x 100 pixels',
    et: 'Recommended image size: 100 x 100 pixels',
    lt: 'Recommended image size: 100 x 100 pixels',
    ch: 'Recommended image size: 100 x 100 pixels',
    ph: 'Recommended image size: 100 x 100 pixels',
  },
  '**Resolution size greater than 100 x 100 pixels is accepted. (e.g. 256 x 256 pixels)': {
    en: '**Resolution size greater than 100 x 100 pixels is accepted. (e.g. 256 x 256 pixels)',
    ja: '**Resolution size greater than 100 x 100 pixels is accepted. (e.g. 256 x 256 pixels)',
    et: '**Resolution size greater than 100 x 100 pixels is accepted. (e.g. 256 x 256 pixels)',
    lt: '**Resolution size greater than 100 x 100 pixels is accepted. (e.g. 256 x 256 pixels)',
    ch: '**Resolution size greater than 100 x 100 pixels is accepted. (e.g. 256 x 256 pixels)',
    ph: '**Resolution size greater than 100 x 100 pixels is accepted. (e.g. 256 x 256 pixels)',
  },
  'No Results': {
    en: 'No Results',
    ja: '結果がありません',
    et: 'Tulemused puuduvad',
    lt: 'No Results',
    ch: 'No Results',
    ph: 'No Results',
  },
  minutes: {
    en: 'minutes',
    ja: 'minutes',
    et: 'minutes',
    lt: 'minutes',
    ch: 'minutes',
    ph: 'minutes',
  },
  'Modify Badge': {
    en: 'Modify Badge',
    ja: 'Modify Badge',
    et: 'Modify Badge',
    lt: 'Modify Badge',
    ch: 'Modify Badge',
    ph: 'Modify Badge',
  },
  'Modify Challenge': {
    en: 'Modify Challenge',
    ja: 'Modify Challenge',
    et: 'Modify Challenge',
    lt: 'Modify Challenge',
    ch: 'Modify Challenge',
    ph: 'Modify Challenge',
  },
  'Please select a challenge to add': {
    en: 'Please select a challenge to add',
    ja: 'Please select a challenge to add',
    et: 'Please select a challenge to add',
    lt: 'Please select a challenge to add',
    ch: 'Please select a challenge to add',
    ph: 'Please select a challenge to add',
  },
  'Please select a badge to add': {
    en: 'Please select a badge to add',
    ja: 'Please select a badge to add',
    et: 'Please select a badge to add',
    lt: 'Please select a badge to add',
    ch: 'Please select a badge to add',
    ph: 'Please select a badge to add',
  },
  'Provide the narrative or explanation for this badge in about 100 words': {
    en: 'Provide the narrative or explanation for this badge in about 100 words',
    ja: 'Provide the narrative or explanation for this badge in about 100 words',
    et: 'Provide the narrative or explanation for this badge in about 100 words',
    lt: 'Provide the narrative or explanation for this badge in about 100 words',
    ch: 'Provide the narrative or explanation for this badge in about 100 words',
    ph: 'Provide the narrative or explanation for this badge in about 100 words',
  },
  'Provide the narrative or explanation for this challenge in about 100 words': {
    en: 'Provide the narrative or explanation for this challenge in about 100 words',
    ja: 'Provide the narrative or explanation for this challenge in about 100 words',
    et: 'Provide the narrative or explanation for this challenge in about 100 words',
    lt: 'Provide the narrative or explanation for this challenge in about 100 words',
    ch: 'Provide the narrative or explanation for this challenge in about 100 words',
    ph: 'Provide the narrative or explanation for this challenge in about 100 words',
  },
  Publish: {
    en: 'Publish',
    ja: 'Publish',
    et: 'Publish',
    lt: 'Publish',
    ch: 'Publish',
    ph: 'Publish',
  },
  'Ready to Publish': {
    en: 'Ready to Publish',
    ja: 'Ready to Publish',
    et: 'Ready to Publish',
    lt: 'Ready to Publish',
    ch: 'Ready to Publish',
    ph: 'Ready to Publish',
  },
  'Remove badge for this challenge?': {
    en: 'Remove badge for this challenge?',
    ja: 'Remove badge for this challenge?',
    et: 'Remove badge for this challenge?',
    lt: 'Remove badge for this challenge?',
    ch: 'Remove badge for this challenge?',
    ph: 'Remove badge for this challenge?',
  },
  'Remove challenge for this badge?': {
    en: 'Remove challenge for this badge?',
    ja: 'Remove challenge for this badge?',
    et: 'Remove challenge for this badge?',
    lt: 'Remove challenge for this badge?',
    ch: 'Remove challenge for this badge?',
    ph: 'Remove challenge for this badge?',
  },
  Save: {
    en: 'Save',
    ja: '保存',
    et: 'Salvesta',
    lt: 'Save',
    ch: 'Save',
    ph: 'Save',
  },
  'Save / Publish': {
    en: 'Save / Publish',
    ja: 'Save / Publish',
    et: 'Save / Publish',
    lt: 'Save / Publish',
    ch: 'Save / Publish',
    ph: 'Save / Publish',
  },
  'The name of the badge is required': {
    en: 'The name of the badge is required',
    ja: 'The name of the badge is required',
    et: 'The name of the badge is required',
    lt: 'The name of the badge is required',
    ch: 'The name of the badge is required',
    ph: 'The name of the badge is required',
  },
  'The name of the challenge is required': {
    en: 'The name of the challenge is required',
    ja: 'The name of the challenge is required',
    et: 'The name of the challenge is required',
    lt: 'The name of the challenge is required',
    ch: 'The name of the challenge is required',
    ph: 'The name of the challenge is required',
  },
  'Thumbnail Image': {
    en: 'Thumbnail Image',
    ja: 'Thumbnail Image',
    et: 'Thumbnail Image',
    lt: 'Thumbnail Image',
    ch: 'Thumbnail Image',
    ph: 'Thumbnail Image',
  },
  'Tools & Materials': {
    en: 'Tools & Materials',
    ja: 'Tools & Materials',
    et: 'Tools & Materials',
    lt: 'Tools & Materials',
    ch: 'Tools & Materials',
    ph: 'Tools & Materials',
  },
  'Upload Badge Image': {
    en: 'Upload Badge Image',
    ja: 'Upload Badge Image',
    et: 'Upload Badge Image',
    lt: 'Upload Badge Image',
    ch: 'Upload Badge Image',
    ph: 'Upload Badge Image',
  },
  'Upload Cover Image': {
    en: 'Upload Cover Image',
    ja: 'Upload Cover Image',
    et: 'Upload Cover Image',
    lt: 'Upload Cover Image',
    ch: 'Upload Cover Image',
    ph: 'Upload Cover Image',
  },
  'Upload Thumbnail Image': {
    en: 'Upload Thumbnail Image',
    ja: 'Upload Thumbnail Image',
    et: 'Upload Thumbnail Image',
    lt: 'Upload Thumbnail Image',
    ch: 'Upload Thumbnail Image',
    ph: 'Upload Thumbnail Image',
  },
  'Useful Tips': {
    en: 'Useful Tips',
    ja: 'Useful Tips',
    et: 'Useful Tips',
    lt: 'Useful Tips',
    ch: 'Upload Tips',
    ph: 'Upload Tips',
  },
  'What categories does this badge belong to?': {
    en: 'What categories does this badge belong to?',
    ja: 'What categories does this badge belong to?',
    et: 'What categories does this badge belong to?',
    lt: 'What categories does this badge belong to?',
    ch: 'What categories does this badge belong to?',
    ph: 'What categories does this badge belong to?',
  },
  'What categories does this challenge belong to?': {
    en: 'What categories does this challenge belong to?',
    ja: 'What categories does this challenge belong to?',
    et: 'What categories does this challenge belong to?',
    lt: 'What categories does this challenge belong to?',
    ch: 'What categories does this challenge belong to?',
    ph: 'What categories does this challenge belong to?',
  },
  'Saved badge order': {
    en: 'Saved badge order',
    ja: 'Saved badge order',
    et: 'Saved badge order',
    lt: 'Saved badge order',
    ch: 'Saved badge order',
    ph: 'Saved badge order',
  },
  'Edit badge order': {
    en: 'Edit badge order',
    ja: 'Edit badge order',
    et: 'Edit badge order',
    lt: 'Edit badge order',
    ch: 'Edit badge order',
    ph: 'Edit badge order',
  },
  'Saved challenge order': {
    en: 'Saved challenge order',
    ja: 'Saved challenge order',
    et: 'Saved challenge order',
    lt: 'Saved challenge order',
    ch: 'Saved challenge order',
    ph: 'Saved challenge order',
  },
  'Edit challenge order': {
    en: 'Edit challenge order',
    ja: 'Edit challenge order',
    et: 'Edit challenge order',
    lt: 'Edit challenge order',
    ch: 'Edit challenge order',
    ph: 'Edit challenge order',
  },
};
