import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import './challenges.scss';

import Button from 'src/components/common/button/button';
import Loading from 'src/components/common/loading/loading';
import Pagination from 'src/components/common/pagination/pagination';
import MyImage from 'src/components/common/MyImage';

import ChallengeSvg from 'src/css/imgs/icon-badge.svg';
import { ReactComponent as MenuSvg } from 'src/css/imgs/icon-menu.svg';
import PreloadChallengeImage from 'src/css/imgs/placeholder-square-s.jpeg';

import { BadgeOrderType } from 'src/enums/BadgeOrderType';

import ChallengeApi from 'src/apis/viviboom/ChallengeApi';
import BadgeCategoryApi from 'src/apis/viviboom/BadgeCategoryApi';

import InstitutionApi from 'src/apis/viviboom/InstitutionApi';
import ChallengeModal from './challenge-modal';

const DEFAULT_LIMIT = 20;
const challengeImageParams = { suffix: 'png' };

function Challenges({ authToUpdate, authToCreate }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badge' });
  const user = useSelector((state) => state.user);
  const { state } = useLocation();

  const [showNewChallenge, setShowNewChallenge] = useState(!!state?.isCreate);
  const [selectedChallenge, setSelectedChallenge] = useState(null);

  // for the tabs: -1 is 'all categories'
  const [badgeCategoryId, setBadgeCategoryId] = useState(-1);
  const [selectedBadgeCategory, setSelectedBadgeCategory] = useState(null);

  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [challenges, setChallenges] = useState([]); 
  const [badgeCategories, setBadgeCategories] = useState([]);
  const [challengeOrders, setChallengeOrders] = useState([]);

  const [keywords, setKeywords] = useState('');
  const [filterOrder, setFilterOrder] = useState(BadgeOrderType.INSTITUTION_ORDER);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isPublished, setIsPublished] = useState(true);

  const [isOrderChanged, setIsOrderChanged] = useState(false);

  const [isEnableOrderEdit, setIsEnableOrderEdit] = useState(false);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const addChallengeOrder = (challengeList = challenges) => {
    let defaultFirstChallengeOrder = 1;
    if (page > 1) defaultFirstChallengeOrder = DEFAULT_LIMIT * (page - 1);
    const oldChallengeOrders = challengeOrders;
    challengeList.forEach((challenge, index) => {
      const challengeOrder = oldChallengeOrders.find((b) => b?.id === challenge?.id);
      if (challengeOrder) challengeOrder.order = (defaultFirstChallengeOrder + index);
      else oldChallengeOrders.push({ id: challenge?.id, order: (defaultFirstChallengeOrder + index), isDelete: false });
    });
    setChallengeOrders(oldChallengeOrders);
  };

  const fetchCategories = useCallback(async () => {
    if (!user?.authToken) return;

    setCategoriesLoading(true);
    try {
      const res = await BadgeCategoryApi.getList({ authToken: user?.authToken });
      setBadgeCategories(res.data?.badgeCategories);
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
    setCategoriesLoading(false);
  }, [user?.authToken]);

  const fetchChallenges = useCallback(async (newPage = page) => {
    setChallenges([]);

    const requestParams = {
      authToken: user?.authToken,
      verboseAttributes: ['createdByUser', 'categories', 'content'],
      isPublished,
      limit: DEFAULT_LIMIT,
      offset: (newPage - 1) * DEFAULT_LIMIT,
    };

    // if badgeCategoryId is undefined, fetch all badges, else:
    if (badgeCategoryId > 0) requestParams.badgeCategoryId = badgeCategoryId;
    if (keywords) requestParams.keywords = keywords;
    if (filterOrder !== BadgeOrderType.INSTITUTION_ORDER) requestParams.order = filterOrder;

    try {
      const res = await ChallengeApi.getList(requestParams);
      setChallenges(res.data.challenges);
      setTotalPages(res.data?.totalPages);
      return res.data.challenges;
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
    return [];
  }, [page, user?.authToken, isPublished, badgeCategoryId, keywords, filterOrder]);

  const fetchChallenge = useCallback(async (orderId) => {
    const requestParams = {
      authToken: user?.authToken,
      verboseAttributes: ['createdByUser', 'categories', 'content'],
      badgeInstitutionOrderValue: orderId,
      isPublished,
    };

    try {
      const res = await ChallengeApi.getList(requestParams);
      return res.data?.challenges[0];
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
    return [];
  }, [isPublished, user?.authToken]);

  const saveChallenges = useCallback(async () => {
    const requestParams = {
      authToken: user?.authToken,
      institutionId: user?.institutionId,
      challenges: challengeOrders,
    };
    try {
      await InstitutionApi.patch(requestParams);
      if (isOrderChanged) toast.success(t('Saved challenge order'));
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
    setIsOrderChanged(false);
  }, [isOrderChanged, page, challenges, user?.authToken, user?.institutionId, t]);

  const enableOrderFetchChallenges = async () => {
    let firstChallenge = [];
    let lastChallenge = [];

    let tempChallenges = await fetchChallenges();

    if (page > 1 && totalPages > 1) {
      const firstChallengeOrderId = ((page - 1) * DEFAULT_LIMIT);
      firstChallenge = await fetchChallenge(firstChallengeOrderId);
      tempChallenges = [firstChallenge, ...tempChallenges];
    }
    if (page < totalPages && totalPages > 1) {
      const lastChallengeOrderId = (page * DEFAULT_LIMIT) + 1;
      lastChallenge = await fetchChallenge(lastChallengeOrderId);
      tempChallenges = [...tempChallenges, lastChallenge];
    }
    setChallenges(tempChallenges);
  };

  const handleSaveChallenges = async () => {
    setLoading(true);
    await saveChallenges();
    setIsEnableOrderEdit(false);
    setLoading(false);
  };

  const handleClose = () => {
    setShowNewChallenge(false);
    setSelectedChallenge(null);
  };

  const handleCategoryClick = (id, index) => () => {
    if (id === badgeCategoryId && id > 0) {
      setSelectedBadgeCategory(badgeCategories[index]);
      return;
    }
    setPage(1);
    setTotalPages(1);
    setKeywords('');
    setBadgeCategoryId(id);
    setIsOrderChanged(false);
    if (id === -1) setFilterOrder(BadgeOrderType.INSTITUTION_ORDER);
    else setFilterOrder(BadgeOrderType.LATEST);
    if (id === 0) setIsPublished(false);
    else setIsPublished(true);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();

    setBadgeCategoryId(-1);
    setPage(1);
    setTotalPages(1);
    setIsOrderChanged(false);
    await fetchChallenges(1);
  };

  const handleOrderChange = async (e) => {
    setFilterOrder(e.target.value);
    setPage(1);
    setTotalPages(1);
  };

  const dropChallenge = async () => {
    const tempChallengesList = [...challenges];
    const dragItemContent = tempChallengesList[dragItem.current];
    tempChallengesList.splice(dragItem.current, 1);
    tempChallengesList.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    await addChallengeOrder(tempChallengesList);
    setChallenges(tempChallengesList);
    setIsOrderChanged(true);
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    e.preventDefault();
    e.target.parentElement.classList.add('dragOver');
  };

  const dragDrop = (e) => {
    e.target.parentElement.classList.remove('dragOver');
  };

  const dragLeave = (e) => {
    e.target.parentElement.classList.remove('dragOver');
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    setLoading(true);
    if (isEnableOrderEdit && !isOrderChanged) enableOrderFetchChallenges();
    else fetchChallenges();
    setLoading(false);
  }, [isEnableOrderEdit, page, totalPages, isPublished, badgeCategoryId, keywords, filterOrder]);


  return (
    <div className="challenges">
      <div className="challenges-container">
        <div className="challenges-category-content">
          <h1>{t('Challenges')}</h1>
          <ul>
            <li className={(badgeCategoryId === -1) ? 'active' : ''} onClick={handleCategoryClick(-1)}>All Categories</li>
            <li className={(badgeCategoryId === 0) ? 'active' : ''} onClick={handleCategoryClick(0)}>Drafts</li>
            {
              badgeCategories.map((v, index) => (
                <li
                  key={`badge-category_${v.id}`}
                  className={(badgeCategoryId === v.id) ? 'active' : ''}
                  onClick={handleCategoryClick(v.id, index)}
                >
                  {v.name}
                </li>
              ))
            }
            <Loading show={categoriesLoading} size="24px" />
          </ul>
        </div>
        <div className="challenges-content">
          <div className="challenges-header">
            <div className="challenges-search">
              <form onSubmit={handleSearchSubmit}>
                <input type="text" value={keywords} placeholder="Search Challenges" onChange={(e) => setKeywords(e.target.value)} />
                <select value={filterOrder} onChange={handleOrderChange}>
                  {badgeCategoryId === -1 && keywords === '' && <option value={BadgeOrderType.INSTITUTION_ORDER}>Custom Order</option>}
                  <option value={BadgeOrderType.LATEST}>Latest</option>
                  <option value={BadgeOrderType.OLDEST}>Oldest</option>
                </select>
                <Button type="submit" status="search" value="Search" className="button" />
              </form>

            </div>
            <div className="challenges-add">
              <Button
                status="add"
                className="button"
                disabled={!authToCreate}
                onClick={() => setShowNewChallenge(true)}
              >
                {t('New Challenge')}
              </Button>
            </div>
          </div>

          <div className="challenges-list">
            <table>
              <thead>
                <tr className="header">
                  <th>{t('Challenge Image')}</th>
                  <th>{t('Name')}</th>
                  <th>{t('Created Date')}</th>
                </tr>
              </thead>
              <tbody>
                {filterOrder === BadgeOrderType.INSTITUTION_ORDER && isEnableOrderEdit ? (
                  challenges.map((v, index) => (
                  // Cases for challenge details to be hidden
                    (totalPages > 1 && ((page === 1 && index === DEFAULT_LIMIT)
                    || (page === totalPages && index === 0)
                    || (page > 1 && page < totalPages && index === 0)
                    || (page > 1 && page < totalPages && index === DEFAULT_LIMIT + 1))) ? (
                      <tr
                        className="last-first-challenge"
                        key={`challenge_${v?.id}`}
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragLeave={(e) => dragLeave(e)}
                        onDragEnd={dropChallenge}
                      >
                        {((page === 1 && index === DEFAULT_LIMIT) || (page > 1 && index === DEFAULT_LIMIT + 1)) && <td colSpan={3}>Drag here to insert after this page</td>}
                        {(page > 1 && index === 0) && <td colSpan={3}>Drag here to insert before this page</td>}
                      </tr>
                      ) : (
                        <tr
                          className="other-challenge"
                          id={index}
                          key={`challenge_${v.id}`}
                          onClick={() => setSelectedChallenge(v)}
                          onDragStart={(e) => dragStart(e, index)}
                          onDragOver={(e) => e.preventDefault()}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragLeave={(e) => dragLeave(e)}
                          onDrop={(e) => dragDrop(e)}
                          onDragEnd={dropChallenge}
                          draggable={filterOrder === BadgeOrderType.INSTITUTION_ORDER}
                        >
                          <td className="image">
                            <MyImage src={v.imageUri} alt={v.name} preloadImage={PreloadChallengeImage} defaultImage={ChallengeSvg} params={challengeImageParams} width={128} />
                          </td>
                          <td>{v.name || '(no name)'}</td>
                          <td>{DateTime.fromISO(v.createdAt).toLocaleString(DateTime.DATE_MED)}</td>
                          <td><MenuSvg className="draggable-icon" /></td>
                        </tr>
                      )
                  ))
                ) : (
                  challenges.map((v) => (
                    <tr className="challenge-row" key={`challenge_${v.id}`} onClick={() => setSelectedChallenge(v)}>
                      <td className="image">
                        <MyImage src={v.imageUri} alt={v.name} preloadImage={PreloadChallengeImage} defaultImage={ChallengeSvg} params={challengeImageParams} width={128} />
                      </td>
                      <td>{v.name || '(no name)'}</td>
                      <td>{DateTime.fromISO(v.createdAt).toLocaleString(DateTime.DATE_MED)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Loading show={loading} size="40px" />
            {!challenges?.length && <div className="no-results">{t('No Results')}</div>}
            <div className="challenges-footer">
              {!isEnableOrderEdit && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
              {badgeCategoryId === -1 && filterOrder === BadgeOrderType.INSTITUTION_ORDER && !isEnableOrderEdit && (
              <div className="order-edit-button">
                <Button
                  status=""
                  className="button"
                  disabled={!authToUpdate}
                  onClick={() => (setIsEnableOrderEdit(true))}
                >
                  {t('Edit challenge order')}
                </Button>
              </div>
              )}
              {badgeCategoryId === -1 && filterOrder === BadgeOrderType.INSTITUTION_ORDER && isEnableOrderEdit && (
              <div className="order-save-button-container">
                <div className="order-save-button">
                  <Button
                    status=""
                    className="button"
                    disabled={!authToUpdate}
                    onClick={() => (setIsEnableOrderEdit(false))}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
                <div className="order-save-button">
                  <Button
                    status=""
                    className="button"
                    disabled={!authToUpdate}
                    onClick={() => (handleSaveChallenges())}
                  >
                    {t('Save custom challenge order')}
                  </Button>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ChallengeModal
        show={selectedChallenge || showNewChallenge}
        handleClose={handleClose}
        refreshChallenges={fetchChallenges}
        challenge={selectedChallenge}
        allBadgeCategories={badgeCategories}
        authToCreate={authToCreate}
        authToUpdate={authToUpdate}
        setIsOrderChanged={setIsOrderChanged}
      />
    </div>
  );
}

export default Challenges;
