import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import './badges.scss';

import Button from 'src/components/common/button/button';
import Loading from 'src/components/common/loading/loading';
import Pagination from 'src/components/common/pagination/pagination';
import MyImage from 'src/components/common/MyImage';

import BadgeSvg from 'src/css/imgs/icon-badge.svg';
import { ReactComponent as MenuSvg } from 'src/css/imgs/icon-menu.svg';
import PreloadBadgeImage from 'src/css/imgs/placeholder-square-s.jpeg';

import { BadgeOrderType } from 'src/enums/BadgeOrderType';

import BadgeApi from 'src/apis/viviboom/BadgeApi';
import BadgeCategoryApi from 'src/apis/viviboom/BadgeCategoryApi';

import InstitutionApi from 'src/apis/viviboom/InstitutionApi';
import BadgeModal from './badge-modal';

const DEFAULT_LIMIT = 20;

const badgeImageParams = { suffix: 'png' };

function Badges({ authToUpdate, authToCreate }) {
  const { t } = useTranslation('translation', { keyPrefix: 'badge' });
  const user = useSelector((state) => state.user);
  const { state } = useLocation();

  const [showNewBadge, setShowNewBadge] = useState(!!state?.isCreate);
  const [selectedBadge, setSelectedBadge] = useState(null);

  // for create/modify badge category
  const [showNewBadgeCategory, setShowNewBadgeCategory] = useState(false);
  const [selectedBadgeCategory, setSelectedBadgeCategory] = useState(null);

  // for the tabs: -1 is 'all categories'
  const [badgeCategoryId, setBadgeCategoryId] = useState(-1);

  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [badges, setBadges] = useState([]);
  const [badgeCategories, setBadgeCategories] = useState([]);
  const [badgeOrders, setBadgeOrders] = useState([]);

  const [keywords, setKeywords] = useState('');
  const [filterOrder, setFilterOrder] = useState(BadgeOrderType.INSTITUTION_ORDER);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isPublished, setIsPublished] = useState(true);

  const [isOrderChanged, setIsOrderChanged] = useState(false);

  const [isEnableOrderEdit, setIsEnableOrderEdit] = useState(false);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const addBadgeOrder = (badgeList = badges) => {
    let defaultFirstBadgeOrder = 1;
    if (page > 1) defaultFirstBadgeOrder = DEFAULT_LIMIT * (page - 1);
    const oldBadgeOrders = badgeOrders;
    badgeList.forEach((badge, index) => {
      const badgeOrder = oldBadgeOrders.find((b) => b?.id === badge?.id);
      if (badgeOrder) badgeOrder.order = (defaultFirstBadgeOrder + index);
      else oldBadgeOrders.push({ id: badge?.id, order: (defaultFirstBadgeOrder + index), isDelete: false });
    });
    setBadgeOrders(oldBadgeOrders);
  };

  const fetchCategories = useCallback(async () => {
    if (!user?.authToken) return;

    setCategoriesLoading(true);
    try {
      const res = await BadgeCategoryApi.getList({ authToken: user.authToken });
      setBadgeCategories(res.data?.badgeCategories);
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
    setCategoriesLoading(false);
  }, [user?.authToken]);

  const fetchBadges = useCallback(async (newPage = page) => {
    setBadges([]);

    const requestParams = {
      authToken: user?.authToken,
      verboseAttributes: ['createdByUser', 'categories', 'content'],
      isPublished,
      limit: DEFAULT_LIMIT,
      offset: (newPage - 1) * DEFAULT_LIMIT,
    };

    // if badgeCategoryId is undefined, fetch all badges, else:
    if (badgeCategoryId > 0) requestParams.badgeCategoryId = badgeCategoryId;
    if (keywords) requestParams.keywords = keywords;
    if (filterOrder !== BadgeOrderType.INSTITUTION_ORDER) requestParams.order = filterOrder;

    try {
      const res = await BadgeApi.getList(requestParams);
      setBadges(res.data.badges);
      setTotalPages(res.data?.totalPages);
      return res.data.badges;
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
    return [];
  }, [page, user?.authToken, isPublished, badgeCategoryId, keywords, filterOrder]);

  const fetchBadge = useCallback(async (orderId) => {
    const requestParams = {
      authToken: user?.authToken,
      verboseAttributes: ['createdByUser', 'categories', 'content'],
      badgeInstitutionOrderValue: orderId,
      isPublished,
    };

    try {
      const res = await BadgeApi.getList(requestParams);
      return res.data?.badges[0];
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
    return [];
  }, [isPublished, user?.authToken]);

  const saveBadges = useCallback(async () => {
    const requestParams = {
      authToken: user?.authToken,
      institutionId: user?.institutionId,
      badges: badgeOrders,
    };
    try {
      await InstitutionApi.patch(requestParams);
      if (isOrderChanged) toast.success(t('Saved badge order'));
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
    setIsOrderChanged(false);
  }, [isOrderChanged, user?.authToken, user?.institutionId, badgeOrders, t]);

  const enableOrderFetchBadges = async () => {
    let firstBadge = [];
    let lastBadge = [];

    let tempBadges = await fetchBadges();

    if (page > 1 && totalPages > 1) {
      const firstBadgeOrderId = ((page - 1) * DEFAULT_LIMIT);
      firstBadge = await fetchBadge(firstBadgeOrderId);
      tempBadges = [firstBadge, ...tempBadges];
    }
    if (page < totalPages && totalPages > 1) {
      const lastBadgeOrderId = (page * DEFAULT_LIMIT) + 1;
      lastBadge = await fetchBadge(lastBadgeOrderId);
      tempBadges = [...tempBadges, lastBadge];
    }
    setBadges(tempBadges);
  };

  const handleSaveBadges = async () => {
    await saveBadges();
    setIsEnableOrderEdit(false);
  };

  const handleClose = () => {
    setShowNewBadge(false);
    setSelectedBadge(null);
    setShowNewBadgeCategory(false);
    setSelectedBadgeCategory(null);
  };

  const handleCategoryClick = (id, index) => () => {
    if (id === badgeCategoryId && id > 0) {
      setSelectedBadgeCategory(badgeCategories[index]);
      return;
    }
    setPage(1);
    setTotalPages(1);
    setKeywords('');
    setBadgeCategoryId(id);
    setIsOrderChanged(false);
    if (id === -1) setFilterOrder(BadgeOrderType.INSTITUTION_ORDER);
    else setFilterOrder(BadgeOrderType.LATEST);
    if (id === 0) setIsPublished(false);
    else setIsPublished(true);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();

    setBadgeCategoryId(-1);
    setPage(1);
    setTotalPages(1);
    setIsOrderChanged(false);
    await fetchBadges(1);
  };

  const handleOrderChange = async (e) => {
    setFilterOrder(e.target.value);
    setPage(1);
    setTotalPages(1);
  };

  const dropBadge = async () => {
    const tempBadgesList = [...badges];
    const dragItemContent = tempBadgesList[dragItem.current];
    tempBadgesList.splice(dragItem.current, 1);
    tempBadgesList.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    await addBadgeOrder(tempBadgesList);
    setBadges(tempBadgesList);
    setIsOrderChanged(true);
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    e.preventDefault();
    e.target.parentElement.classList.add('dragOver');
  };

  const dragDrop = (e) => {
    e.target.parentElement.classList.remove('dragOver');
  };

  const dragLeave = (e) => {
    e.target.parentElement.classList.remove('dragOver');
  };

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    setLoading(true);
    if (isEnableOrderEdit && !isOrderChanged) enableOrderFetchBadges();
    else fetchBadges();
    setLoading(false);
  }, [isEnableOrderEdit, isOrderChanged, page, totalPages, isPublished, badgeCategoryId, keywords, filterOrder]);

  return (
    <div className="badges">
      <div className="badges-container">
        <div className="badges-category-content">
          <h1>{t('Badges')}</h1>
          <ul>
            <li className={(badgeCategoryId === -1) ? 'active' : ''} onClick={handleCategoryClick(-1)}>All Categories</li>
            <li className={(badgeCategoryId === 0) ? 'active' : ''} onClick={handleCategoryClick(0)}>Drafts</li>
            {
              badgeCategories.map((v, index) => (
                <li
                  key={`badge-category_${v.id}`}
                  className={(badgeCategoryId === v.id) ? 'active' : ''}
                  onClick={handleCategoryClick(v.id, index)}
                >
                  {v.name}
                </li>
              ))
            }
            <Loading show={categoriesLoading} size="24px" />
            <li className="new-category">
              <Button
                status="add"
                disabled={!authToCreate}
                onClick={() => setShowNewBadgeCategory(true)}
              >
                {t('New Category')}
              </Button>
            </li>
          </ul>
        </div>
        <div className="badges-content">
          <div className="badges-header">
            <div className="badges-search">
              <form onSubmit={handleSearchSubmit}>
                <input type="text" value={keywords} placeholder="Search Badges" onChange={(e) => setKeywords(e.target.value)} />
                <select value={filterOrder} onChange={handleOrderChange}>
                  {badgeCategoryId === -1 && keywords === '' && <option value={BadgeOrderType.INSTITUTION_ORDER}>Custom Order</option>}
                  <option value={BadgeOrderType.LATEST}>Latest</option>
                  <option value={BadgeOrderType.OLDEST}>Oldest</option>
                </select>
                <Button type="submit" status="search" value="Search" className="button" />
              </form>

            </div>
            <div className="badges-add">
              <Button
                status="add"
                className="button"
                disabled={!authToCreate}
                onClick={() => setShowNewBadge(true)}
              >
                {t('New Badge')}
              </Button>
            </div>
          </div>

          <div className="badges-list">
            <table>
              <thead>
                <tr className="header">
                  <th>{t('Badge Image')}</th>
                  <th>{t('Name')}</th>
                  <th>{t('Created Date')}</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {filterOrder === BadgeOrderType.INSTITUTION_ORDER && isEnableOrderEdit ? (
                  badges.map((v, index) => (
                    // Cases for badge details to be hidden
                    (totalPages > 1 && ((page === 1 && index === DEFAULT_LIMIT)
                    || (page === totalPages && index === 0)
                    || (page > 1 && page < totalPages && index === 0)
                    || (page > 1 && page < totalPages && index === DEFAULT_LIMIT + 1))) ? (
                      <tr
                        className="last-first-badge"
                        key={`badge_${v?.id}`}
                        onDragOver={(e) => e.preventDefault()}
                        onDragEnter={(e) => dragEnter(e, index)}
                        onDragLeave={(e) => dragLeave(e)}
                        onDragEnd={dropBadge}
                      >
                        {((page === 1 && index === DEFAULT_LIMIT) || (page > 1 && index === DEFAULT_LIMIT + 1)) && <td colSpan={3}>Drag here to insert after this page</td>}
                        {(page > 1 && index === 0) && <td colSpan={3}>Drag here to insert before this page</td>}
                      </tr>
                      ) : (
                        <tr
                          className="other-badge"
                          id={index}
                          key={`badge_${v.id}`}
                          onClick={() => setSelectedBadge(v)}
                          onDragStart={(e) => dragStart(e, index)}
                          onDragOver={(e) => e.preventDefault()}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragLeave={(e) => dragLeave(e)}
                          onDrop={(e) => dragDrop(e)}
                          onDragEnd={dropBadge}
                          draggable={filterOrder === BadgeOrderType.INSTITUTION_ORDER}
                        >
                          <td className="image">
                            <MyImage src={v?.imageUri} alt={v.name} preloadImage={PreloadBadgeImage} defaultImage={BadgeSvg} params={badgeImageParams} width={128} />
                          </td>
                          <td>{v.name || '(no name)'}</td>
                          <td>{DateTime.fromISO(v.createdAt).toLocaleString(DateTime.DATE_MED)}</td>
                          <td><MenuSvg className="draggable-icon" /></td>
                        </tr>
                      )
                  ))
                ) : (
                  badges.map((v) => (
                    <tr className="badge-row" key={`badge_${v.id}`} onClick={() => setSelectedBadge(v)}>
                      <td className="image">
                        <MyImage src={v?.imageUri} alt={v.name} preloadImage={PreloadBadgeImage} defaultImage={BadgeSvg} params={badgeImageParams} width={128} />
                      </td>
                      <td>{v.name || '(no name)'}</td>
                      <td>{DateTime.fromISO(v.createdAt).toLocaleString(DateTime.DATE_MED)}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Loading show={loading} size="40px" />
            {!badges?.length && <div className="no-results">{t('No Results')}</div>}
            <div className="badges-footer">
              {!isEnableOrderEdit && <Pagination page={page} totalPages={totalPages} setPage={setPage} />}
              {badgeCategoryId === -1 && filterOrder === BadgeOrderType.INSTITUTION_ORDER && !isEnableOrderEdit && (
              <div className="order-edit-button">
                <Button
                  status=""
                  className="button"
                  disabled={!authToUpdate}
                  onClick={() => (setIsEnableOrderEdit(true))}
                >
                  {t('Edit badge order')}
                </Button>
              </div>
              )}
              {badgeCategoryId === -1 && filterOrder === BadgeOrderType.INSTITUTION_ORDER && isEnableOrderEdit && (
              <div className="order-save-button-container">
                <div className="order-save-button">
                  <Button
                    status=""
                    className="button"
                    disabled={!authToUpdate}
                    onClick={() => (setIsEnableOrderEdit(false))}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
                <div className="order-save-button">
                  <Button
                    status=""
                    className="button"
                    disabled={!authToUpdate}
                    onClick={() => (handleSaveBadges())}
                  >
                    {t('Save custom badge order')}
                  </Button>
                </div>
              </div>
              )}
            </div>
          </div>
        </div>
        <BadgeModal
          show={selectedBadge || showNewBadge}
          handleClose={handleClose}
          refreshBadges={fetchBadges}
          badge={selectedBadge}
          allBadgeCategories={badgeCategories}
          authToCreate={authToCreate}
          authToUpdate={authToUpdate}
          setIsOrderChanged={setIsOrderChanged}
        />
      </div>
    </div>
  );
}

export default Badges;
