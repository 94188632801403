export const BadgeOrderType = {
  LATEST: 'LATEST',
  OLDEST: 'OLDEST',
  RANDOM: 'RANDOM',
  INSTITUTION_ORDER: 'INSTITUTION_ORDER',
};

export const badgeOrderTypes = [
  BadgeOrderType.LATEST,
  BadgeOrderType.OLDEST,
  BadgeOrderType.RANDOM,
  BadgeOrderType.INSTITUTION_ORDER,
];
